<template>
	<Modal
		class="edit-preview-modal"
		:value="visible"
		:fullscreen="fullscreen"
		:mask-closable="false"
		:closable="true"
		@on-cancel="onCancel"
		:styles="{ top: fullscreen ? 0 : '20px' }"
		width="760"
	>
		<Spin fix v-if="loading"></Spin>
		<div slot="header" class="modal-header">
			<h2>查看详情</h2>
			<a
				href="javascript:;"
				class="fullscreen"
				@click="fullscreen = !fullscreen"
			>
				<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
					<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
					<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
				</Tooltip>
			</a>
		</div>
		<Form :model="viewData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="24">
					<h2 class="title">
						{{ viewData.title }}
					</h2>
				</Col>

				<Col span="8" class="mb16"> 发布时间: {{ viewData.createdTime }} </Col>
				<Col span="8" class="mb16"> 更新时间: {{ viewData.updatedTime }} </Col>
				<Col span="8" class="mb16"> 文章类型: {{ viewData.typeName }} </Col>
				<Col span="24">
					<div
						v-html="viewData.content"
						style="overflow: auto"
						:style="{ height: conHeight + 'px' }"
					></div>
				</Col>
				<div class="attachTitle" v-if="defaultList.length">附件资料：</div>
				<Col span="24" v-for="(item, index) in defaultList" :key="index">
					<p class="attachItem">
						<a @click="downloadFile(item)">{{ item.name }}</a>
					</p>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button type="default" @click="onCancel">关闭</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/notice/notice"
import { mapState } from "vuex"

const { apiGet, apiGetFiles } = api
export default {
	name: "EditPreview",
	props: ["viewId", "visible", "typeList"],
	data() {
		return {
			viewData: {},
			loading: false,
			fullscreen: false,
			defaultList: []
		}
	},
	computed: {
		...mapState({
			contentHeight: state => state.contentHeight - 160
		}),
		conHeight() {
			return this.fullscreen
				? document.body.clientHeight - 280
				: this.contentHeight
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
			this.fullscreen = false
		},
		getFileList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetFiles({ id: this.viewId })
				if (res) {
					this.defaultList = res.data
				}
				this.loading = false
			})
		},
		downloadFile(item) {
			window.open(
				`${process.env.VUE_APP_REQUEST_BASE_URL}/document/attachment/inline/${item.id}?token=${this.$store.state.user.token}`
			)
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.viewData = {}
				if (this.viewId) {
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiGet({ id: this.viewId })
						if (res) {
							this.viewData = {
								...this.viewData,
								...res.data
							}
							this.viewData.typeName = this.typeList.find(
								o => o.k === this.viewData.type
							).v
							this.getFileList()
						}
						this.loading = false
					})
				}
			}
		}
	}
}
</script>
<style lang="less">
.edit-preview-modal {
	.title {
		line-height: 32px;
	}
	.attachTitle {
		font-size: 16px;
		line-height: 1;
		padding: 15px;
		color: #212d3c;
	}
	.attachItem {
		line-height: 25px;
	}
	.mb16 {
		color: #999;
		height: 32px;
		line-height: 32px;
	}
	.modal-header {
		overflow: hidden;
		h2 {
			float: left;
		}
		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}
		.iconfont {
			font-size: 20px;
		}
	}
}
</style>
