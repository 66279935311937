import request from "@/utils/request"
import commonApi from "@/api/api"

const moduleName = "/common/notice"

// 验证参数是否合法
function validateParams(data, requiredKeys) {
	for (const key of requiredKeys) {
		if (
			!data[key] ||
			typeof data[key] !== "string" ||
			data[key].trim() === ""
		) {
			throw new Error(`Missing or invalid parameter: ${key}`)
		}
	}
}

export default {
	...commonApi(moduleName),

	// 发布通知
	publishNotice: data => {
		validateParams(data, ["id"])
		return request(`${moduleName}/${data.id}/publish`, {
			method: "put",
			body: data
		})
	},

	// 获取文件列表
	getFiles: data => {
		validateParams(data, ["id"])
		return request(`${moduleName}/${data.id}/files`, {
			method: "get"
		})
	},

	// 删除文件
	deleteFile: data => {
		validateParams(data, ["id", "fileId"])
		return request(`${moduleName}/${data.id}/files/${data.fileId}`, {
			method: "delete"
		})
	},

	// 获取已发布的通知页面
	getPublishedPage: data => {
		validateParams(data, [])
		return request("/common/notice/published", {
			method: "get",
			params: data
		})
	}
}
