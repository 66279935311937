import { render, staticRenderFns } from "./EditPreview.vue?vue&type=template&id=de6097f8"
import script from "./EditPreview.vue?vue&type=script&lang=js"
export * from "./EditPreview.vue?vue&type=script&lang=js"
import style0 from "./EditPreview.vue?vue&type=style&index=0&id=de6097f8&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\KRHealth\\code\\develop\\kr-web-ctms\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('de6097f8')) {
      api.createRecord('de6097f8', component.options)
    } else {
      api.reload('de6097f8', component.options)
    }
    module.hot.accept("./EditPreview.vue?vue&type=template&id=de6097f8", function () {
      api.rerender('de6097f8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BusinessComponents/EditPreview/EditPreview.vue"
export default component.exports